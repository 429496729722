import { contactsCompareFn } from "src/common/contacts.helper";
import { TAddress } from "src/common/form/base/address-inputs.component";
import { TDependentRelationship } from "src/common/types";
import { mapIdsToItems } from "src/common/utils.helpers";
import { ClientBody } from "src/resources/client-modal.resource";

export function clientToFormData({ client, teamMembers, teams }: { client: any; teamMembers: any[]; teams: any[] }) {
  if (!client) return {};

  const generalMappedUsers = mapIdsToItems(client.general_assigned?.users, teamMembers);
  const generalMappedTeams = mapIdsToItems(client.general_assigned?.teams, teams);

  const externalNameDisabled = client.use_name_as_external ?? true;

  const data = {
    ...client,
    use_external_name: !externalNameDisabled,
    // BE auto populates external_display_name with name if use_name_as_external is true, so overwriting it here so that the form field will be empty unless use_name_as_external is false
    external_display_name: externalNameDisabled ? "" : client.external_display_name,
    client_since: client.client_since ? new Date(client.client_since) : new Date(),
    client_owner: teamMembers.find((tm) => tm.id === client.client_owner_id),
    source: client.relationships?.client_sources?.[0],
    source_referred_by: client.relationships?.clients?.find((c: any) => c.relationship_type === "referral") || null,
    custom_fields: client.custom_fields?.reduce?.((acc: any, customField: any) => {
      let value =
        customField.dropdown_values?.map((v: any) => ({ key: v.id || v.key, value: v.value })) || customField.value;
      if (customField.type === "dropdown") {
        value = value[0];
      }
      acc[customField.field_id] = value;
      return acc;
    }, {}),
    roles: client.role_assignments?.reduce((acc: any, role: any) => {
      const mappedUsers = mapIdsToItems(role.users, teamMembers);
      const mappedTeams = mapIdsToItems(role.teams, teams);
      acc[role.role_id] = [...mappedUsers, ...mappedTeams];
      return acc;
    }, {}),
    userTeams: [...generalMappedUsers, ...generalMappedTeams],
    tags: client.relationships?.tags || [],

    contacts:
      client?.contacts?.sort(contactsCompareFn)?.map((contact: any) => ({
        contact: contact,
        contact_description: contact.contact_description,
        contact_type: contact.contact_type,
        dependent_relationship: contact.dependent_relationship,
        mode: "add_existing",
      })) || [],

    addresses:
      client.addresses?.map((address: any) => ({
        isPrimary: address.is_primary,
        type: address.key,
        ...address.value,
      })) || [],
    phones:
      client.phones?.map((phone: any) => ({
        isPrimary: phone.is_primary,
        type: phone.key,
        phone: phone.number,
        extension: phone.extension,
      })) || [],
    emails:
      client.emails?.map((email: any) => ({
        isPrimary: email.is_primary,
        type: email.key,
        email: email.value,
      })) || [],
  };

  return data;
}

type DropdownValue = {
  key: string;
  value: string;
};

type CustomFields = {
  [key: string]: string | DropdownValue | DropdownValue[];
};

type ClientOwner = {
  id: string;
  role: string;
  name: string;
};

type FilingStatus = {
  key: string;
  value: string;
};

type BasicResource = {
  id: string;
  name: string;
};

type User = BasicResource & {
  role: string;
};

type Team = BasicResource & {
  users: User[];
};

type Email = {
  isPrimary: boolean;
  type: string;
  email: string;
};

type Phone = {
  isPrimary: boolean;
  type: string;
  phone: string;
  extension?: string;
};

type Address = TAddress & {
  isPrimary: boolean;
  type: string;
};

type Contact = {
  contact: {
    id: string;
    name: string;
  };
  contact_description?: string;
  contact_type?: "primary" | "spouse" | "dependent" | "other";
  dependent_relationship?: TDependentRelationship;
};

type FormData = {
  client_since: Date;
  is_business: boolean;
  is_active: boolean;
  client_type: string;
  name: string;
  use_external_name: boolean;
  external_display_name?: string;
  client_owner?: ClientOwner;
  external_id: string;
  custom_fields: CustomFields;
  notes: string;
  filing_status: FilingStatus;
  tags: BasicResource[];
  source: BasicResource;
  source_referred_by?: BasicResource;
  userTeams: (User | Team)[];
  roles: {
    [roleId: string]: (User | Team)[];
  };

  business_name: string;
  date_established: string;
  tin: string;
  business_type: string;
  industry: string;

  emails: Email[];
  phones: Phone[];
  addresses: Address[];

  contacts: Contact[];
  third_party_contact?: {
    id: string;
    name: string;
  };
};

type thirdPartyContactData = {
  overrideIntegrationSync: boolean;
  originalId: string | undefined;
  syncClientToIntegration: boolean;
};

function isUser(resource: User | Team): resource is User {
  return (resource as User).role === "TeamMember";
}

function isTeam(resource: User | Team): resource is Team {
  return Object.hasOwnProperty.call(resource, "users");
}

export function formToClientBody(formData: FormData, thirdPartyContactData: thirdPartyContactData): ClientBody {
  const is_business = formData.is_business;
  const { overrideIntegrationSync, syncClientToIntegration, originalId } = thirdPartyContactData;
  return {
    is_business,
    client_since: formData.client_since?.getTime() || new Date().getTime(),
    is_active: formData.is_active,
    client_type: formData.client_type,
    name: formData.name,
    use_name_as_external: !formData.use_external_name,
    ...(formData.use_external_name ? { external_display_name: formData.external_display_name } : {}),
    client_owner_id: formData.client_owner?.id || "",
    external_id: formData.external_id,
    custom_fields: Object.entries(formData.custom_fields || {}).map(([key, value]: any) => {
      if (!value) {
        return { field_id: key, value: "", dropdown_values: [] };
      } else if (typeof value === "string") {
        return { field_id: key, value };
      } else if (value instanceof Date) {
        return { field_id: key, value: value.toISOString() };
      } else if (Array.isArray(value)) {
        return { field_id: key, dropdown_values: value.map((v) => v.key) };
      } else {
        return { field_id: key, dropdown_values: [value.key] };
      }
    }),
    notes: formData.notes,
    filing_status: formData.filing_status,
    tags: formData.tags,
    source: {
      ...formData.source,
      selected: formData.source_referred_by,
    },
    rolesUsersTeams: {
      users: formData.userTeams.filter((ut) => isUser(ut)).map((u) => u.id),
      teams: formData.userTeams.filter((ut) => isTeam(ut)).map((t) => t.id),
      role_assignments: Object.entries(formData.roles || {}).map(([roleId, userTeams]) => {
        return {
          role_id: roleId,
          users: userTeams.filter((ut) => isUser(ut)).map((u) => u.id),
          teams: userTeams.filter((ut) => isTeam(ut)).map((t) => t.id),
        };
      }),
    },

    business_name: formData.business_name,
    date_established: new Date(formData.date_established).getTime(),
    tin: formData.tin,
    business_type: formData.business_type,
    industry: formData.industry,

    emails: is_business
      ? formData.emails
          .filter((e) => !!e.email)
          .map((e) => ({
            is_primary: e.isPrimary,
            key: e.type,
            value: e.email,
          }))
      : [],

    phones: is_business
      ? formData.phones
          .filter((p) => !!p.phone)
          .map((p) => ({
            is_primary: p.isPrimary,
            key: p.type,
            extension: p.extension,
            value: p.phone,
          }))
      : [],

    addresses: is_business
      ? formData.addresses
          .filter((a) => !!a.address_1)
          .map((a) => ({
            is_primary: a.isPrimary,
            key: a.type,
            value: {
              address_1: a.address_1,
              address_2: a.address_2,
              country: a.country,
              region: a.region,
              locality: a.locality,
              postal_code: a.postal_code,
            },
          }))
      : [],

    contacts: [
      ...(formData.contacts
        ?.filter((contact) => !!contact.contact?.id)
        .map((contact) => ({
          id: contact.contact.id,
          contact_type: contact.contact_type,
          contact_description: contact.contact_description,
          dependent_relationship: contact.contact_type === "dependent" ? contact.dependent_relationship : undefined,
        })) || []),
    ],
    ...(overrideIntegrationSync && !!formData?.third_party_contact && syncClientToIntegration
      ? {
          canopy_contact_id_for_third_party: formData.third_party_contact.id,
          original_canopy_contact_id_for_third_party: originalId,
        }
      : {}),
    ...(overrideIntegrationSync ? { canopy_clients_create_in_third_party_override: syncClientToIntegration } : {}),
  };
}
