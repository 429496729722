import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { NavContent } from "primary-navbar!sofe";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import {
  CpTabsDynamic,
  CpButton,
  CpTable,
  CpInput,
  CpPages,
  CpEmptyState,
  CpLoader,
  CpTooltip,
} from "canopy-styleguide!sofe";
import styles from "./contact-list.styles.css";
import { genContactListSchema, getDefaultVisibleColumns } from "./contact-list-schema";
import { AddContact } from "./add-contact.component";
import { ExportContactListButton } from "./export-contact-list/export-contact-list.component";
import { ContextMenu } from "./context-menu.component";
import useContactsListQuery from "./use-contacts-list-query.hook";
import { CustomizeContactFilterViewsModal } from "./customize-contact-filter-views-modal.component";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";
import { KebabMenu } from "./kebab-menu.component";
import { SaveFiltersButton } from "./save-filters-button.component";
import { useContactListFilters } from "./use-contact-list-filters.hook";
import { patchContactFilterView } from "src/resources/contacts.resource";
import { useParams } from "react-router-dom";
import { handleError } from "src/error";
import { contactFilterViewQueries } from "src/queries";
import { useContactListSubView } from "./use-contact-list-subview";
import { BulkActions } from "./bulk-actions";
import { featureEnabled } from "feature-toggles!sofe";

export type FilterViewType = {
  id: string;
  name: string;
  filter_data: {
    visible_columns?: string[];
    column_order: string[];
    filters: Record<string, any>;
    sort_data: any[];
  };
};

export type SelectionType = {
  type: string;
  totalSelected: number;
  toArray: () => string[];
};

export default function ContactList() {
  const { id: routeId } = useParams<{ id: string }>();
  const [user] = useWithUserAndTenant();
  const userHasAccess = hasAccess(user, true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(() => {
    const limitId = localStorage.getItem("clients-ui:contact-list-page-limit");
    return limitId || "50";
  });
  const { subView, allSubViews, exitSubView } = useContactListSubView({ routeId });

  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();

  const schema = CpTable.useSchema(() => genContactListSchema(subView?.id), [subView?.id]);

  const {
    activeFilterView,
    cpTableFilterControl,
    filterViewOverrides,
    filterViews,
    filters,
    newFiltersCount,
    resetFilters,
    setFilterViewOverrides,
    sortData,
    switchFilterView,
  }: any = useContactListFilters({ schema, routeId, subView, allSubViews });

  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [columnWidths, setColumnWidths] = useState({});

  const [showCustomizeModal, setShowCustomizeModal] = useState(false);

  // Array of column ids that are visible
  const visibleColumns = useMemo(() => {
    return (
      filterViewOverrides[activeFilterView?.id]?.visibleColumns ||
      activeFilterView?.filter_data?.visible_columns ||
      getDefaultVisibleColumns()
    );
  }, [filterViewOverrides, activeFilterView]);

  // Array of field ids that are visible, only needed for the contact search endpoint
  const visibleFields = useMemo(() => {
    const toFieldId = (columnId: string) => schema[columnId].fieldId;
    return (
      filterViewOverrides[activeFilterView?.id]?.visibleColumns?.map(toFieldId) ||
      activeFilterView?.filter_data?.visible_columns?.map(toFieldId) ||
      getDefaultVisibleColumns().map(toFieldId)
    );
  }, [filterViewOverrides, activeFilterView, schema]);

  const infiniteScroll = limit === "All";
  const { contacts, contactsQuery, pageCount, totalContactsCount } = useContactsListQuery({
    page,
    limit,
    infiniteScroll,
    search: debouncedSearch,
    filters,
    sortData,
    visibleFields,
    disabled: !activeFilterView,
  });

  const fetchNextPage = useMemo(() => debounce(() => contactsQuery.fetchNextPage(), 100), [contactsQuery]);

  const selection = CpTable.useSelection({ totalSize: totalContactsCount });

  const deselectAll = selection.deselectAll;
  useEffect(() => {
    deselectAll();
  }, [filters, debouncedSearch, deselectAll]);

  function onLimitChange(val: string) {
    setPage(1);
    setLimit(val);
    localStorage.setItem("clients-ui:contact-list-page-limit", val);
  }

  useEffect(() => {
    setColumnOrder(
      filterViewOverrides[activeFilterView?.id]?.columnOrder || activeFilterView?.filter_data?.column_order || []
    );
  }, [activeFilterView, filterViewOverrides]);

  function onColumnOrderChange(newOrder: string[]) {
    setColumnOrder(newOrder);
    if (activeFilterView.created_by === "__DEFAULT") {
      setFilterViewOverrides((prev: any) => ({
        ...prev,
        [activeFilterView.id]: {
          ...prev[activeFilterView.id],
          columnOrder: newOrder,
        },
      }));
    } else {
      patchContactFilterView(activeFilterView.id, {
        filter_data: {
          ...activeFilterView.filter_data,
          column_order: newOrder,
        },
      }).subscribe(() => {
        contactFilterViewQueries.invalidate();
      }, handleError);
    }
  }

  function getEmptyState() {
    if (contactsQuery.isLoading || !contactsQuery.isFetched) {
      return () => <CpLoader size="lg" />;
    } else if (debouncedSearch.length > 0 && contacts.length <= 0) {
      return () => (
        <CpEmptyState img="es_clients_search" text="No search results" subText="Please refine your search criteria." />
      );
    } else if (contacts.length <= 0) {
      return () => <CpEmptyState img="es_filter" text="No results" subText="Please refine your filters." />;
    } else {
      return null;
    }
  }

  return (
    <NavContent className={styles.contactListContainer}>
      <div className={styles.topBar}>
        <div className={styles.header}>
          {subView && (
            <CpTooltip text="Back to Contact List">
              <CpButton
                btnType="icon"
                aria-label="Back to Contact List"
                icon="caret-large-left"
                className="cp-mr-12"
                onClick={() => exitSubView()}
              />
            </CpTooltip>
          )}
          <div className="cp-subheader cp-ml-12">{subView ? subView.name : "Contact List"}</div>
        </div>
        <div className="flex cp-gap-24">
          <AddContact />
          <KebabMenu
            filterViews={filterViews}
            filterViewOverrides={filterViewOverrides}
            selection={selection}
            currentFilters={filters}
            currentSortData={sortData}
            currentFilterViewId={activeFilterView?.id}
            globalSearch={search}
            totalContactsCount={totalContactsCount}
            schema={schema}
            subView={subView}
          />
        </div>
      </div>
      {filterViews?.length > 0 && !subView && (
        <CpTabsDynamic
          tabsData={filterViews}
          activeId={routeId && routeId !== "all" ? routeId : "__ALL_CONTACTS"}
          onChange={(viewId: string) => switchFilterView(viewId)}
        />
      )}
      <CpTable.ActionBar>
        <div className="flex cp-gap-16 items-center">
          {!subView && (
            <CpButton icon="organize-boards" aria-label="Customize" onClick={() => setShowCustomizeModal(true)} />
          )}
          <ExportContactListButton
            filterView={activeFilterView}
            filterViewOverrides={filterViewOverrides}
            filters={filters}
            sortData={sortData}
            search={search}
            selection={selection}
            schema={schema}
          />
          {totalContactsCount > 0 && (
            <span>
              {selection.totalSelected > 0
                ? `${selection.totalSelected.toLocaleString()} of ${totalContactsCount.toLocaleString()} ${
                    totalContactsCount === 1 ? "contact" : "contacts"
                  } selected`
                : `${totalContactsCount.toLocaleString()} ${totalContactsCount === 1 ? "contact" : "contacts"}`}
            </span>
          )}
          {subView && selection.totalSelected > 0 && userHasAccess(subView.clientActionPermissions) && (
            <CpButton
              onClick={() => {
                subView.onClientActionClick({ selection, filters, search });
              }}
              btnType="flat"
            >
              {subView.clientActionName({ selectionCount: selection.totalSelected })}
            </CpButton>
          )}
          {newFiltersCount > 0 && (
            <>
              <SaveFiltersButton
                newFiltersCount={newFiltersCount}
                filters={filters}
                sortData={sortData}
                filterView={activeFilterView}
                filterViewOverrides={filterViewOverrides}
                onFilterViewCreate={(viewId) => switchFilterView(viewId)}
              />
              <CpButton btnType="tertiary" onClick={resetFilters}>
                Reset filter
              </CpButton>
            </>
          )}
        </div>
        <div>
          {(featureEnabled("ft_archive_contacts") || featureEnabled("ft_crm_bulk_email_contacts")) &&
          selection.totalSelected > 0 ? (
            <BulkActions
              allowedActions={subView?.bulkActions}
              filters={filters}
              search={search}
              selection={selection}
            />
          ) : (
            <CpInput
              isSearch
              placeholder="Search contact list"
              value={search}
              onChange={updateSearch}
              onClear={() => updateSearch("")}
            />
          )}
        </div>
      </CpTable.ActionBar>
      <CpTable
        data={contacts}
        schema={schema}
        columnOrder={columnOrder}
        selection={selection}
        filterControl={cpTableFilterControl}
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
        onColumnOrderChange={onColumnOrderChange}
        renderContextMenu={({ clickedResource }: any) => <ContextMenu contact={clickedResource} subView={subView} />}
        renderEmptyState={getEmptyState()}
        visibleColumns={visibleColumns}
        onScroll={(e: any) => {
          if (!infiniteScroll) return;
          const scrollAmt = e.target.scrollTop + e.target.clientHeight;
          const nearBottom = scrollAmt >= e.target.scrollHeight * 0.85;
          if (nearBottom) {
            if (!contactsQuery.isFetchingNextPage && contactsQuery.hasNextPage) {
              fetchNextPage();
            }
          }
        }}
      />
      <CpTable.Footer>
        <CpPages
          totalPages={infiniteScroll ? 1 : pageCount || 1}
          onChange={setPage}
          currentPage={page}
          limit={limit}
          onLimitChange={onLimitChange}
          limitOptions={["50", "100", "200", "All"]}
        />
      </CpTable.Footer>
      {showCustomizeModal && (
        <CustomizeContactFilterViewsModal
          initialFilterView={activeFilterView}
          filterViews={filterViews}
          filters={filters}
          sortData={sortData}
          schema={schema}
          onAfterClose={() => setShowCustomizeModal(false)}
          filterViewOverrides={filterViewOverrides}
          setFilterViewOverrides={setFilterViewOverrides}
          onClose={({ filterViewIdsMarkedForDelete }: any) => {
            if (filterViewIdsMarkedForDelete.includes(activeFilterView.id)) {
              switchFilterView("__ALL_CONTACTS");
            }
          }}
        />
      )}
    </NavContent>
  );
}
