import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import FieldSection from "src/common/field-section.component";
import { InlineContact } from "./inline-contact.component";
import { useController, useFieldArray, useFormContext } from "react-hook-form";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { contactTypes } from "src/common/form/contact-type.component";
import { TContact } from "src/common/types";

const individualContactTypes = contactTypes.filter((contactType) => contactType.individualOnly).map((c) => c.id);

type ContactsSectionProps = {
  isBusiness: boolean;
  isEdit: boolean;
  thirdPartyContact?: TContact;
  newContactSectionRef?: ForwardedRef<HTMLDivElement>;
};

export const ContactsSection = forwardRef(
  ({ isBusiness, isEdit, thirdPartyContact, newContactSectionRef }: ContactsSectionProps, ref: ForwardedRef<any>) => {
    useCssModules(css);
    const sectionRef = useRef<HTMLElement | null>(null);
    const [warningModalIndex, setWarningModalIndex] = useState<number | undefined>();
    const fieldName = "contacts";
    const {
      control,
      watch,
      setValue,
      clearErrors,
      formState: { errors },
    } = useFormContext();
    const { fields, append, remove } = useFieldArray({
      name: fieldName,
      control,
    });

    const contacts = watch(fieldName);
    const hasPrimaryContact = contacts?.some((c: any) => c.contact_type === "primary");

    const {
      fieldState: { error },
    } = useController({
      name: `contacts_section`,
      control,
      rules: {
        required: hasPrimaryContact || isBusiness ? false : "Client must have a primary contact",
      },
    });

    watch((data, { name }) => {
      const contactTypePattern = /^contacts\.\d+\.contact_type$/;
      if (name && contactTypePattern.test(name)) {
        // Ensure that only one primary or spouse contact is selected
        const index = parseInt(name.split(".")[1]);
        const enforceOneType = (contactType: string) => {
          const typeCount = data.contacts.filter((c: any) => c.contact_type === contactType).length;
          if (data.contacts[index].contact_type === contactType && typeCount > 1) {
            const otherIndex = data.contacts.findIndex(
              (contact: any, i: number) => contact.contact_type === contactType && i !== index
            );
            setValue(`contacts.${otherIndex}.contact_type`, "other");
          }
        };
        enforceOneType("primary");
        enforceOneType("spouse");
        clearErrors("contacts_section");
      } else if (name === "contacts") {
        clearErrors("contacts_section");
      } else if (name === "is_business") {
        // If you switch to business change all contacts with "individual" contact types to other
        if (data.is_business) {
          data.contacts.forEach((c: any, index: number) => {
            if (individualContactTypes.includes(c.contact_type)) {
              setValue(`contacts.${index}.contact_type`, "other");
            }
          });
        }
      }
    });

    useEffect(() => {
      if (sectionRef.current && (error || errors?.contacts)) {
        const contactErrors: any = errors?.contacts;
        const err = contactErrors?.find((c: any) => !!c);
        /* I think the ideal way to do this is to use the ref provided in useController/Controller instead of the section.
           The only issue with that is that it wraps around the ref and doesn't actually return the ref, it only returns like 4 methods, and they only work on input elements.
           I think we can still make that work but we'd need to update styleguide to allow passing refs to the CpSelect component
           Also we're ignoring mode_validation errors as those need to scrollIntoView at the bottom of the div. Check use-mode-validation hook */
        if (err && !("mode_validation" in err)) {
          sectionRef.current.scrollIntoView();
        }
      }
    }, [error, errors?.contacts]);

    return (
      <FieldSection
        label="Contacts"
        error={error?.message}
        ref={(el) => {
          if (typeof ref === "function") {
            ref(el);
          } else if (ref) {
            ref.current = el;
          }
          sectionRef.current = el;
        }}
      >
        {fields.map((field, index) => {
          const contact = contacts[index];
          return (
            <InlineContact
              key={field.id}
              control={control}
              fieldName={`${fieldName}.${index}`}
              mode={contact?.mode}
              onModeChange={(mode) => setValue(`${fieldName}.${index}.mode`, mode)}
              onClose={() => {
                if (contact?.mode === "edit") {
                  setValue(`${fieldName}.${index}.mode`, "add_existing");
                } else if (!!thirdPartyContact?.id && contact.contact?.id === thirdPartyContact.id) {
                  setWarningModalIndex(index);
                } else {
                  remove(index);
                }
              }}
              required
              isBusiness={isBusiness}
              isEdit={isEdit}
            />
          );
        })}
        {fields.length > 0 && <div className={s.separator} />}
        <div className="flex cp-gap-16" ref={newContactSectionRef}>
          <CpButton btnType="flat" onClick={() => append({ mode: "add_existing" }, { shouldFocus: false })}>
            Add existing contact
          </CpButton>
          <CpButton btnType="flat" onClick={() => append({ mode: "create" })}>
            Create new contact
          </CpButton>
        </div>
        <CpModal show={warningModalIndex !== undefined} onClose={() => setWarningModalIndex(undefined)}>
          <CpModal.Header title="Remove contact" />
          <CpModal.Body>
            <div>
              <div className="cp-mb-16">
                Removing the contact
                {warningModalIndex !== undefined && !!contacts?.[warningModalIndex]
                  ? `, ${contacts[warningModalIndex]?.contact?.name}, `
                  : " "}
                from the client record will affect the selections you have made for your QBO sync.
              </div>
              <div>Please review your QBO client sync preferences.</div>
            </div>
          </CpModal.Body>
          <CpModal.Footer>
            <CpButton
              btnType="primary"
              className="cp-mr-8"
              onClick={() => {
                remove(warningModalIndex);
                setWarningModalIndex(undefined);
              }}
            >
              Remove
            </CpButton>
            <CpButton btnType="flat" onClick={() => setWarningModalIndex(undefined)}>
              Cancel
            </CpButton>
          </CpModal.Footer>
        </CpModal>
      </FieldSection>
    );
  }
);

const { css, s } = cssModules`
  .separator {
    border-bottom: 0.1rem solid var(--cp-color-app-border);
    padding-top: 0.8rem;
  }
`;
