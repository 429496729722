import React, { lazy, Suspense } from "react";
import AsyncDecorator from "async-decorator/rx6";
import { get } from "lodash";
// project
import styles from "./create-edit-client-modal.styles.css";
import CreateEditClientForm from "src/create-edit-client-old/create-edit-client-form/create-edit-client-form.component.js";
import { mapClientToModel, mapModelToAPI } from "src/mapping-layer/mapping-layer.helpers.js";
import { createClient, updateClient } from "src/create-edit-client-old/create-edit-client.resource.js";
// sofe
import { CpButton, CpLoader, CpModal, cdnImage, CpToggle, CpWell } from "canopy-styleguide!sofe";
import { infoToast, warningToast } from "toast-service!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";
import canopyUrls from "canopy-urls!sofe";
import { handleError } from "src/error";
import { clientQueries } from "src/queries";

type Props = {
  allowDismiss?: () => void;
  preventDismiss?: () => void;
  loading: boolean;
  client: any;
  cancelWhenUnmounted: Function;
  isCreate: boolean;
  error?: any;
  hideBusinessChooser: boolean;
  customFields: Array<any>;
  redirectAsPrimary: boolean;
  clientsUsageData?: any;
  isClientLimitModel?: boolean;
  qboIntegrationInfo?: any;
  checkIntegrationAuth?: any;
  loggedInUser?: any;
  roles?: any;
  onModalHide?: (modalResponse: any) => void;
};

type State = {
  showModal: boolean;
  createRequestInProgress: boolean;
  updateRequestInProgress: boolean;
  syncClientToIntegration: boolean;
  overrideIntegrationSync: boolean;
};

const IntegrationSyncErrorBanner = lazy(() =>
  SystemJS.import("integrations-ui!sofe").then((module: any) => module.getIntegrationSyncErrorBanner())
);

@AsyncDecorator
@UserTenantProps()
export default class CreateEditClientModalContents extends React.Component<Props, State> {
  static defaultProps = {
    cancelWhenUnmounted: () => {},
    isCreate: false,
    hideBusinessChooser: false,
  };

  form: any = React.createRef();

  state = {
    showModal: true,
    createRequestInProgress: false,
    updateRequestInProgress: false,
    showIntegrationError: false,
    syncClientToIntegration: false,
    overrideIntegrationSync: false,
  };

  componentDidMount(): void {
    const clientIntegration = this.props.qboIntegrationInfo?.clients;
    if (!!clientIntegration?.settings?.actions_from_canopy?.canopy_clients_create_in_third_party_default) {
      this.setState({ syncClientToIntegration: true });
    }

    if (!!clientIntegration?.sync_status?.synced_at) {
      this.setState({ overrideIntegrationSync: true });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const clientIntegration = this.props.qboIntegrationInfo?.clients;
    const clientIntegrationPrev = prevProps.qboIntegrationInfo?.clients;
    if (
      !!clientIntegration?.settings?.actions_from_canopy?.canopy_clients_create_in_third_party_default &&
      !clientIntegrationPrev?.settings?.actions_from_canopy?.canopy_clients_create_in_third_party_default
    ) {
      this.setState({ syncClientToIntegration: true });
    }

    if (!!clientIntegration?.sync_status?.synced_at && !clientIntegrationPrev?.sync_status?.synced_at) {
      this.setState({ overrideIntegrationSync: true });
    }
  }

  render() {
    const { loading, error, customFields, redirectAsPrimary, qboIntegrationInfo, checkIntegrationAuth, onModalHide } =
      this.props;
    const client_mapped_to_model = mapClientToModel(this.props.client);
    const isEdit = client_mapped_to_model != undefined && this.props.isCreate === false;
    let title = isEdit ? "Edit" : "Add";
    if (loading) {
      title = "Loading";
    }

    const isDisabled = this.state.createRequestInProgress || this.state.updateRequestInProgress;

    return (
      <CpModal
        show={this.state.showModal}
        width={736}
        onClose={this.closeModalClick}
        onAfterClose={() => onModalHide?.(this.props.client)}
        data-testid="add-edit-client-modal"
      >
        <CpModal.Header title={`${title} Client`}>
          {this.props.clientsUsageData &&
            this.props.clientsUsageData.allowed &&
            this.props.clientsUsageData.allowed - this.props.clientsUsageData.used <= 20 && (
              <div className={styles.clientLimit}>
                {`${this.props.clientsUsageData.used}/${this.props.clientsUsageData.allowed} clients`}
              </div>
            )}
        </CpModal.Header>
        <CpModal.Body className={styles.modalBody}>
          {qboIntegrationInfo?.connected && qboIntegrationInfo?.disconnect_error && (
            <div className="cp-mh-36 cp-mt-16">
              <Suspense fallback={<></>}>
                <IntegrationSyncErrorBanner
                  integrationInfo={qboIntegrationInfo}
                  checkIntegrationAuth={checkIntegrationAuth}
                />
              </Suspense>
            </div>
          )}
          {!isEdit && qboIntegrationInfo?.connected && !!qboIntegrationInfo?.clients?.sync_status.synced_at && (
            <CpWell level={2} className={styles.integrationToggleSection}>
              <div className="cp-flex-center">
                <img src={cdnImage("qbo_logo_small_circle.svg")} height={24} className="cp-mr-8" />
                <strong>Sync client</strong>
              </div>
              <CpToggle
                checked={this.state.syncClientToIntegration}
                onChange={(syncClientToIntegration: boolean) => this.setState({ syncClientToIntegration })}
              />
            </CpWell>
          )}
          {this.getModalBody({
            loading,
            error,
            isEdit,
            client: client_mapped_to_model,
            customFields,
            disabled: isDisabled,
          })}
        </CpModal.Body>
        <CpModal.Footer>
          <div>
            <CpButton
              form="create_edit_client_form"
              className="cp-mr-16"
              type="submit"
              btnType="primary"
              disabled={isDisabled}
              showLoader={isDisabled}
            >
              {getPrimaryButtonText(isEdit, redirectAsPrimary)}
            </CpButton>
            {!isEdit && redirectAsPrimary && (
              <CpButton
                type="submit"
                btnType="flat"
                onClick={isDisabled ? null : this.getModelAndCreate}
                disabled={isDisabled}
              >
                Create and close
              </CpButton>
            )}
          </div>
        </CpModal.Footer>
      </CpModal>
    );
  }

  getModelAndCreate = () => {
    this.form.current.manualSubmit().then(([valid, model]: any) => {
      if (valid) {
        this.createClient(model);
      }
    });
  };

  getModalBody = ({ loading, error, isEdit, client, customFields, disabled }: any) => {
    if (loading || (this.props.isClientLimitModel && !this.props.clientsUsageData)) {
      return (
        <div className={"cp-m-24"}>
          <CpLoader />
        </div>
      );
    } else if (error) {
      return <div className={"cp-m-24"}>Error loading</div>;
    } else {
      let submitAction: any = () => ({});
      if (isEdit) {
        submitAction = this.updateClient;
      } else if (this.props.redirectAsPrimary) {
        submitAction = this.createClientAndRedirect;
      } else {
        submitAction = this.createClient;
      }

      return (
        <CreateEditClientForm
          isEdit={isEdit}
          client={client}
          onValidSubmit={submitAction}
          hideBusinessChooser={this.props.hideBusinessChooser}
          customFields={customFields}
          ref={this.form}
          isClientLimitModel={this.props.isClientLimitModel}
          clientsUsageData={this.props.clientsUsageData}
          closeModal={this.closeModalClick}
          disabled={disabled}
        />
      );
    }
  };

  closeModalClick = () => {
    if (this.state.updateRequestInProgress === false && this.state.updateRequestInProgress === false) {
      this.handleHideModal();
    }
  };

  handleHideModal = () => {
    this.setState({ showModal: false });
  };

  updateClient = (formModel: any) => {
    const apiClient = mapModelToAPI(formModel);
    const apiClientWithRemovedFields = mergeAndRemoveFields(apiClient, this.props.client);
    this.props.preventDismiss && this.props.preventDismiss();
    this.setState({ updateRequestInProgress: true }, () => {
      const originalTags = get(this.props, "client.relationships.tags", []);
      updateClient(this.props.loggedInUser, apiClientWithRemovedFields, originalTags, this.props.roles).subscribe(
        (client) => {
          infoToast({
            message: `Client updated successfully`,
          });
          window.dispatchEvent(new CustomEvent("clients-ui::client-updated"));
          clientQueries.invalidate();
          this.setState({ updateRequestInProgress: false });
          this.props.allowDismiss && this.props.allowDismiss();
          this.handleHideModal();
        },
        (err) => {
          this.props.allowDismiss && this.props.allowDismiss();
          this.setState({ updateRequestInProgress: false });
          handleError(err);
        }
      );
    });
  };

  createClientAndRedirect = (formModel: any) => {
    this.createClient(formModel, true);
  };

  createClient = (formModel: any, redirect: boolean = false) => {
    const globalCreatedToast = !redirect;
    const syncOverrideObj = this.state.overrideIntegrationSync
      ? {
          canopy_clients_create_in_third_party_override: this.state.syncClientToIntegration,
        }
      : {};
    const apiClient = mapModelToAPI(formModel);
    this.props.preventDismiss && this.props.preventDismiss();
    this.setState({ createRequestInProgress: true }, () => {
      createClient(
        this.props.loggedInUser,
        { ...apiClient, ...syncOverrideObj },
        globalCreatedToast,
        syncOverrideObj
      ).subscribe(
        (client: any) => {
          this.setState({ createRequestInProgress: false });
          this.props.allowDismiss && this.props.allowDismiss();
          this.handleHideModal();
          const id = client.id;
          if (redirect) {
            canopyUrls.navigateToUrl(`#/client/${id}`);
          } else {
            infoToast({
              message: `Client successfully created`,
              links: [
                {
                  url: `${location.origin}/#/client/${id}`,
                  label: `View Client`,
                },
              ],
            });
          }
        },
        (err: any) => {
          this.props.allowDismiss && this.props.allowDismiss();
          this.setState({ createRequestInProgress: false });
          if (err?.data?.errors?.userMessage === "client limit reached") {
            warningToast("client limit reached");
          } else {
            handleError(err);
          }
        }
      );
    });
  };
}

function getPrimaryButtonText(isEdit: boolean, redirectAsPrimary: boolean) {
  if (isEdit) {
    return "Update";
  } else if (redirectAsPrimary) {
    return "Create and manage";
  } else {
    return "Create";
  }
}

function mergeAndRemoveFields(newClient: any, originalClient: any) {
  const keys = Object.keys(originalClient);
  const nullKeys = ["date_established", "client_since", "birthdate", "client_owner_id", "business_type", "industry"];
  const stringKeys = ["tin"];
  const arrayKeys = ["addresses", "emails", "phones"];
  const cleared = keys.reduce((acc: any, key) => {
    if (newClient[key] === undefined) {
      if (nullKeys.includes(key)) {
        acc[key] = null;
      } else if (stringKeys.includes(key)) {
        acc[key] = "";
      } else if (arrayKeys.includes(key)) {
        acc[key] = [];
      }
    }
    return acc;
  }, {});
  return { ...newClient, ...cleared };
}
