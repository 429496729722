import React, { lazy, Suspense, useRef } from "react";
import { NavContent, checkPermissions } from "primary-navbar!sofe";
import { useCss } from "kremling";
import { CpLoader } from "canopy-styleguide!sofe";
import ClientHomeCard from "./client-home-card.component";
import NotesList from "./notes-list.component";
import ResolutionCasesList from "./resolution-cases-list.component";
import FilesList from "./files-list.component";
import { useHistory, useParams } from "react-router-dom";
const TasksList = lazy(() =>
  SystemJS.import("tasks-ui!sofe")
    .then((tasks) => tasks.getTasksList())
    .then((mod) => mod)
);
const CalendarCard = lazy(() => import("calendar-ui!sofe").then((cal) => cal.getClientCalendarCard()));
import { useHasAccess } from "cp-client-auth!sofe";
import { useClientQuery } from "src/clients-ui";

export default function ClientHomePage({ user }) {
  const notesListRef = useRef();
  const permissions = useRef({ transcriptsOnly: checkPermissions(user, "transcripts-only") });
  const resolutionCasePerm = useHasAccess("tasks_resolution_cases");
  const tasksPerm = useHasAccess("task_dashboard");
  const hasClientFilesAccess = useHasAccess("files_client");
  const hasCommunications = useHasAccess("communications");
  const hasNotesAccess = useHasAccess("notes");
  const history = useHistory();
  const { id: clientId } = useParams();

  const { client } = useClientQuery({
    clientId,
    includes: "users,clients,tags,client_for,client_sources,integrations,roles,general_assigned,contacts,client_groups",
  });

  function renderCards() {
    const taskListCard = (
      <div className="block-item" key="task">
        <ClientHomeCard
          title="Tasks"
          buttonText="View all tasks"
          buttonAction={() => history.push(`/tasks/clients/${clientId}`)}
        >
          <Suspense fallback={<Loader />}>
            <TasksList clientId={client.id} buttonClick={() => history.push(`/tasks/clients/${clientId}`)} />
          </Suspense>
        </ClientHomeCard>
      </div>
    );

    const filesListCard = (
      <div className="block-item" key="files">
        <ClientHomeCard
          title="Recently Added Files"
          buttonText="View all files"
          buttonAction={() => history.push(`/docs/clients/${clientId}/files`)}
        >
          <FilesList clientId={clientId} buttonClick={() => history.push(`/docs/clients/${clientId}/files`)} />
        </ClientHomeCard>
      </div>
    );

    const notesListCard = (
      <div className="block-item" key="notes">
        <ClientHomeCard title="Notes" buttonText="Add a note" buttonAction={() => notesListRef.current?.openModal()}>
          <NotesList ref={notesListRef} clientId={clientId} />
        </ClientHomeCard>
      </div>
    );

    const resolutionCaseListCard = (
      <div className="block-item" key="resolutionCases">
        <ClientHomeCard
          title="Resolution Cases"
          buttonText="Create resolution case"
          buttonAction={() => history.push(`/client/${clientId}/resolution-cases/create`)}
        >
          <ResolutionCasesList
            clientId={clientId}
            buttonClick={() => history.push(`/client/${clientId}/resolution-cases/create`)}
          />
        </ClientHomeCard>
      </div>
    );

    const canShowTasksList = client.is_active && tasksPerm;
    const canShowFilesList = client.is_active && hasClientFilesAccess;
    const canShowNotesList = hasNotesAccess;
    const canShowResolutionCaseList = client.is_active && resolutionCasePerm;

    let cards = [];
    if (permissions.current.transcriptsOnly) {
      cards = [canShowNotesList && notesListCard];
    } else {
      cards = [
        canShowTasksList && taskListCard,
        canShowFilesList && filesListCard,
        canShowNotesList && notesListCard,
        canShowResolutionCaseList && resolutionCaseListCard,
      ];
    }

    cards = cards.filter((card) => card);

    return (
      <>
        <div className="cps-flexible-focus flex-container">{cards}</div>
        {hasCommunications && (
          <div className="cps-card cps-flexible-focus">
            <Suspense fallback={<Loader />}>
              <CalendarCard client={client} />
            </Suspense>
          </div>
        )}
      </>
    );
  }

  return (
    <NavContent hasTopnavSecondary={false} clientMenuPossible={true} className={"with-sidebar"}>
      <div {...useCss(styles)}>{client ? renderCards() : <Loader />}</div>
    </NavContent>
  );
}

const Loader = () => (
  <div className="cps-margin-top-32">
    <CpLoader size="lg" />
  </div>
);

const styles = `
& .flex-container {
  display: flex;
  flex-wrap: wrap;
}
& .block-item {
  width: calc(50% - 2.4rem);
  flex-grow: 1;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  height: 50rem;
}
& .block-item:nth-child(even),
& .block-item:last-child {
  margin-right: 0;
}
`;
